exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-alumni-js": () => import("./../../../src/pages/alumni.js" /* webpackChunkName: "component---src-pages-alumni-js" */),
  "component---src-pages-angels-jury-js": () => import("./../../../src/pages/angels-jury.js" /* webpackChunkName: "component---src-pages-angels-jury-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentor-reg-js": () => import("./../../../src/pages/mentor-reg.js" /* webpackChunkName: "component---src-pages-mentor-reg-js" */),
  "component---src-pages-mentors-js": () => import("./../../../src/pages/mentors.js" /* webpackChunkName: "component---src-pages-mentors-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-pre-reg-js": () => import("./../../../src/pages/pre-reg.js" /* webpackChunkName: "component---src-pages-pre-reg-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-process-js": () => import("./../../../src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-resource-partners-js": () => import("./../../../src/pages/resource-partners.js" /* webpackChunkName: "component---src-pages-resource-partners-js" */),
  "component---src-pages-sponsors-and-partners-js": () => import("./../../../src/pages/sponsors-and-partners.js" /* webpackChunkName: "component---src-pages-sponsors-and-partners-js" */),
  "component---src-pages-tip-js": () => import("./../../../src/pages/tip.js" /* webpackChunkName: "component---src-pages-tip-js" */),
  "component---src-pages-web-3-js": () => import("./../../../src/pages/web3.js" /* webpackChunkName: "component---src-pages-web-3-js" */),
  "component---src-pages-work-with-us-js": () => import("./../../../src/pages/work-with-us.js" /* webpackChunkName: "component---src-pages-work-with-us-js" */)
}

